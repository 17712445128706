import { isSubscriptionStepId } from '@orus.eu/dimensions'
import { useParams } from '@tanstack/react-router'
import { memo } from 'react'
import { NotFound } from '../../organisms/not-found'
import NavigateToNewFunnel from './navigate-to-new-funnel'
import { SubscriptionPageV2 } from './subscription-v2-page'
import { SubscriptionV2StepPageLoader } from './subscription-v2-step-page-loader'

export default memo(
  /**
   * Displays a step page. The step page the the "root" of the a subscription funnel
   * step. Details such as guarantee lists have their own page.
   */
  function SubscriptionV2StepPage() {
    const { subscriptionId, stepId } = useParams({ from: '/subscribe/$subscriptionId/$stepId/' })

    if (legacySubscriptionFunnelPathStarts.has(subscriptionId)) {
      // The legacy subscription funnels have the same path pattern at the new subscription funnel, except that
      // instead of the subscriptionId we have a custom prefix identifying the legacy policy.
      // Doing this redirection with react router is a bit too complicated for such an edge case. So we will to
      // this with a simple redirection.
      return <NavigateToNewFunnel />
    }

    if (!isSubscriptionStepId(stepId)) {
      return <NotFound />
    }

    return (
      <SubscriptionPageV2>
        <SubscriptionV2StepPageLoader requestedSubscriptionId={subscriptionId} requestedStepId={stepId} />
      </SubscriptionPageV2>
    )
  },
)

const legacySubscriptionFunnelPathStarts = new Set([
  'restaurants-v2',
  'freelance',
  'rc-pro-mac',
  'rc-pro-man',
  'rc-pro-media',
  'rc-pro-misc',
  'rc-pro-miscbie',
])
